/**
 * head.js
 *
 * @version 1.0.0
 * @copyright 2018 SEDA.digital GmbH & Co. KG
 */

/*
 * Please note that jQuery/$ is not available in head.js!
 */
// replace no-js class with js class
(function(h){
    h.className = h.className.replace('no-js', 'js');
})(document.documentElement);

import lazySizes from 'lazysizes';
lazySizes.cfg.addClasses = true;
lazySizes.cfg.preloadAfterLoad = true;
lazySizes.cfg.lazyClass = 'u-lazyload';
lazySizes.cfg.preloadClass = 'u-lazypreload';
lazySizes.cfg.loadingClass = 'is-lazyloading';
lazySizes.cfg.loadedClass = 'is-lazyloaded';

import fontloader from '../vendor/fontloader/fontloader.js';
fontloader('webfonts_sedadigital', '/dist/web/fonts-woff.css', '/dist/web/fonts-woff2.css');
